"use client";
import React from "react";
import Image from "next/image";
import defaultImage from "@/public/images/default_image.jpg";
import group from "@/public/images/group.svg";
import cap from "@/public/images/capPrimaryColor.svg";
import user from "@/public/images/user.svg";
import presentaion from "@/public/images/presentaion.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Card = ({
  image,
  title,
  subTitle,
}: {
  image: string;
  title: string;
  subTitle: string;
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 py-[30px] bg-[#323538] rounded-[20px]">
      <Image src={image || defaultImage} alt={subTitle} />
      <p className="text-40 text-primaryColor font-[500]">{title}</p>
      <p className="text-16 text-textColor font-[500]">{subTitle}</p>
    </div>
  );
};

const Index = ({
  full = true,
  achievements,
  loading,
}: {
  full?: boolean;
  achievements: {
    users_count: number;
    teachers_count: number;
    courses_count: number;
    books_count: number;
  };
  loading: boolean;
}) => {
  // Map the achievements data to the format needed for display
  const achievementsData = [
    {
      image: group,
      title: `${achievements?.users_count?.toLocaleString()}+`,
      subTitle: "طالب",
    },
    {
      image: cap,
      title: `${achievements?.teachers_count}+`,
      subTitle: "مدرس علي المنصة",
    },
    {
      image: user,
      title: `${achievements?.courses_count}+`,
      subTitle: "كورس",
    },
    {
      image: presentaion,
      title: `${achievements?.books_count}+`,
      subTitle: "كتاب",
    },
  ];

  return (
    <div id="achievements" className="container my-20">
      {full && (
        <>
          <p className="titleHeading">أنت أيضًا قادر على تحقيق أهدافك معنا.</p>
          <p className="text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10">
            هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
            القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع
          </p>
        </>
      )}
      {loading ? (
        <SkeletonTheme baseColor="#474A4D" highlightColor="#6b6e72">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center gap-5 py-[30px] bg-[#323538] rounded-[20px]"
                >
                  {/* Image skeleton - a square shape */}
                  <div className="flex justify-center">
                    <Skeleton circle height={60} width={60} />
                  </div>

                  {/* Title skeleton - large number */}
                  <Skeleton height={40} width={100} className="mb-2" />

                  {/* Subtitle skeleton - smaller text */}
                  <Skeleton height={20} width={80} />
                </div>
              ))}
          </div>
        </SkeletonTheme>
      ) : achievements ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {achievementsData.map((item, index) => (
            <Card key={index} {...item} />
          ))}
        </div>
      ) : (
        full && (
          <div className="text-center text-red-400 bg-[#474A4D] rounded-[5px] py-5 px-3">
            <p>لم يتم العثور على إنجازات.</p>
          </div>
        )
      )}
    </div>
  );
};

export default Index;
