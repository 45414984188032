"use client";
import Link from "next/link";
import React, { useState } from "react";

import { useAppSelector } from "@/redux/store";
import { SelectLoggedUser } from "@/redux/Auth";
import MyLink from "@/components/shared/myLink/MyLink";
const index = ({ home }: any) => {
  const [showFilter, setshowFilter] = useState(false);
  const loggedUser = useAppSelector(SelectLoggedUser);

  return (
    <div className=" container my-10 z-10 relative">
      <p className=" text-70 text-white font-[700] text-center mb-5">
        واجهتك الأولي <span className=" text-primaryColor">للنجاح</span>
      </p>
      <p className=" text-26 text-textColor max-w-[1390px] mx-auto text-center">
        منصة Eduvalu هي منصة تهدف إلي تطوير العملية التعليمية لمساعدة الطالب في
        تحقق النجاح في جميع المواد والتفوق بها وذلك من خلال الخدمات التي نقدمها.
      </p>
      {loggedUser.isLoggedIn && loggedUser.userToken ? (
        <div className="flex items-center justify-center flex-wrap mt-10 gap-5">
          <MyLink
            href={"/fullExams"}
            className="text-13 font-[500] text-white py-2 px-5 border border-white
           rounded-[7px]"
            style={{
              background:
                "linear-gradient(44deg, rgba(0, 0, 0, 0.08) 37.32%, rgba(0, 0, 0, 0.00) 50.98%), radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
            }}
          >
            عرض الاختبارات الشامله
          </MyLink>
          <MyLink
            href={"/courses"}
            className="text-13 font-[500] text-white py-2 px-5 border border-white
           rounded-[7px]"
            style={{
              background:
                "linear-gradient(44deg, rgba(0, 0, 0, 0.08) 37.32%, rgba(0, 0, 0, 0.00) 50.98%), radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
            }}
          >
            عرض الكورسات
          </MyLink>
          <MyLink
            href={"/books"}
            className="text-13 font-[500] text-white py-2 px-5 border border-white
           rounded-[7px]"
            style={{
              background:
                "linear-gradient(44deg, rgba(0, 0, 0, 0.08) 37.32%, rgba(0, 0, 0, 0.00) 50.98%), radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
            }}
          >
            شراء كتب
          </MyLink>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-10 gap-5">
          <MyLink
            href={"/signup"}
            className=" text-13 font-[500] text-[#202225] bg-primaryColor rounded-[7px] py-2 px-5"
          >
            أنشئ حسابك الان
          </MyLink>
          <MyLink
            href={"/courses"}
            className=" text-13 font-[500] text-white py-2 px-5 border border-white
            rounded-[7px] bg-gradient-to-b from-[#32353827] to-[#32353866]"
          >
            عرض الكورسات
          </MyLink>
        </div>
      )}
    </div>
  );
};

export default index;
